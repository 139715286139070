import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Contact from "../components/general/Contact/Contact";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import Banner from "../components/general/Banner/Banner";
import Introduction from "../components/foretagsevent/Introduction/Introduction";
import UserStories from "../components/foretagsevent/UserStories/UserStories";
import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";

export default () => (
  <div>
    <CustomHelmet
      title="DJ för bröllop i Stockholm - Platoon DJs"
      description="Hyra DJ för företagsevent i Stockholm. Konferenser, After Works, kick offs och personalfester"
      keywords="dj, företag, stockholm, konferens, personalfest"
      canonical="/foretagsevent/"
    />
    <Header />
    <Banner videoUrl={video} tagline="Professionella event för ditt företag " />
    <Introduction />
    <UserStories />
    <Contact />
    <Footer />
  </div>
);
