import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-top: 36px;
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 460px;
  margin-right: 60px;
  color: rgba(0, 0, 0, 0.9);

  @media only screen and (max-width: 960px) {
    margin: 12px 0;
  }
`;
