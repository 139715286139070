import React from "react";
import styled from "styled-components";

import quoteSymbol from "./assets/quote.svg";

const Container = styled.div``;

const Quote = styled.blockquote`
  // display: block;
  // width: max-content;
  margin: 0 auto;
  padding: 24px 0 0 64px;

  background-image: url(${quoteSymbol});
  background-size: 36px;
  background-repeat: no-repeat;

  font-size: 1.375rem;
  font-style: italic;
  line-height: 140%;
`;
const Name = styled.div`
  margin-top: 12px;
  text-align: right;
`;

export default ({
  children,
  marginTop,
  textAlign,
  paddingTop,
  name,
  maxWidth,
  width,
  marginLeft,
  marginBottom
}) => (
  <Container
    className="Quote"
    style={{
      width: width,
      maxWidth: maxWidth,
      marginTop: marginTop || 0,
      marginLeft: marginLeft,
      marginBottom: marginBottom
    }}
  >
    <Quote
      style={{
        textAlign: textAlign || "center",
        paddingTop: paddingTop || 24
      }}
    >
      {children}
    </Quote>
    <Name>{name}</Name>
  </Container>
);
