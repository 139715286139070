import React from "react";

import * as Styles from "./Introduction.styles";

import ContactButton from "../../general/ContactButton/ContactButton";

import NetlightLogo from "./assets/netlight.svg";
import AccentureLogo from "./assets/accenture.svg";
import WspLogo from "./assets/wsp.svg";

export default () => (
  <Styles.Container>
    <Styles.Columns>
      <Styles.TextColumn>
        <Styles.H1>Hyra DJ i Stockholm till företagsevent</Styles.H1>
        <p style={{ marginBottom: 48 }}>
          Från avslappnad lounge till oförglömlig julfest, vi har erfarenheten.
          Vi levererar en helhetslösning med DJ, ljud och ljus, oavsett storlek
          på er tillställning. Genom åren har vi spelat på diverse konferenser,
          after works, kick offs och personalfester. Vi vet vilken musik som får
          just ditt företag att nå sin fulla potential.
        </p>

        <ContactButton />

        <Styles.H3>Några av våra vänner</Styles.H3>

        <Styles.Logos>
          <div
            style={{
              backgroundImage: `url(${NetlightLogo})`,
              backgroundPositionY: 12
            }}
          />
          <div style={{ backgroundImage: `url(${AccentureLogo})` }} />
          <div
            style={{
              backgroundImage: `url(${WspLogo})`,
              backgroundPositionY: 12
            }}
          />
        </Styles.Logos>
      </Styles.TextColumn>
    </Styles.Columns>
  </Styles.Container>
);
