import React from "react";

import * as Styles from "./UserStories.styles";

import Quote from "../../general/Quote/Quote";

export default () => (
  <Styles.Container>
    <Styles.Columns>
      <Styles.TextColumn>
        <Quote name="Romulo, Rektor - Försvarshögskolan">
          Ett riktigt stort och varmt tack för ert bidrag till en oförglömlig
          kväll då Försvarshögskolan firade sitt 200-årsjubileum. Aldrig har så
          många medarbetare uttryckt så mycket glädje och uppskattning över en
          personalfest och ni var en viktig del i detta genom att se till att
          festen slutade i ett rejält tempo i en skön mix som uppskattades av
          gästerna på festens upplopp.
        </Quote>
      </Styles.TextColumn>
    </Styles.Columns>
  </Styles.Container>
);
